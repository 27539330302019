import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

// ToDo: fix swiping on mobile

const buttonStyleOverride = `
  border: none;
  background: none;
  outline: none;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  &:focus {
    outline: none;
  }
`;

const slideStyles = ({ slideSpacing, transitionTime, absolute }) => `
  &.slideLeft {
    .slide-enter {
      flex: 0 0 auto;
      transform: translateX(${ -100 - (slideSpacing * 100)}%);
    }
    .slide-enter-active {
      transform: translateX(0%);
      transition: transform ${transitionTime}ms ease-in-out;
    }
    .slide-exit {
      flex: 0 0 auto;
      transform: translateX(${absolute ? 0 : -100}%);
    }
    .slide-exit-active {
      transform: translateX(${(absolute ? 100 : 0) + (slideSpacing * 100)}%) !important;
      transition: transform ${transitionTime}ms ease-in-out;
    }
  }
  &.slideRight {
    .slide-enter {
      flex: 0 0 auto;
      transform: translateX(${100 + (slideSpacing * 100)}%);
    }
    .slide-enter-active {
      transform: translateX(0%);
      transition: transform ${transitionTime}ms ease-in-out;
    }
    .slide-exit {
      flex: 0 0 auto;
      transform: translateX(${absolute ? 0 : -100}%);
    }
    .slide-exit-active {
      transform: translateX(${-200 - (slideSpacing * 100)}%) !important;
      transition: transform ${transitionTime}ms ease-in-out;
    }
  }
`;

const StyledDiv = styled.div`
  &.fadeIn {
    .slide-enter {
      flex: 0 0 auto;
      opacity: 0;
    }
    .slide-enter-active {
      opacity: 1;
      transition: opacity ${({ transitionTime }) => transitionTime / 2}ms ease-in-out;
    }
    .slide-exit {
      flex: 0 0 auto;
      opacity: 1;
    }
    .slide-exit-active {
      opacity: 0;
    }
  }
  ${({ slideSpacing, transitionTime }) => slideStyles({ slideSpacing, transitionTime })}
  &.absolutePositioning {
    ${({ slideSpacing, transitionTime }) => slideStyles({ slideSpacing, transitionTime, absolute: true })}
  }
  .innerWrapper {
    .arrowButton {
      opacity: 0;
      transition: opacity 500ms;
    }
    &:hover .arrowButton {
      opacity: 1;
    }
  }
`;

const Slide = styled.div`
  cursor: ${({ canSwipe }) => canSwipe ? `grab` : `initial`};
  display: flex;
  justify-content: center;
  position: ${({ absolutePositionSpacer }) => absolutePositionSpacer ? 'absolute' : 'static'};
  width: 100%;
  z-index: 1;
`;

const ArrowButton = styled.button`
  ${ buttonStyleOverride }
  background: ${({ color, direction }) => {
    let arrow;
    switch(direction) {
      case 'right':
        arrow = 'M 37.5 25 L 62.5 50 L 37.5 75';
        break;
      case 'left':
        arrow = 'M 62.5 25 L 37.5 50 L 62.5 75';
        break;
      default:
        arrow = 'M 37.5 25 L 62.5 50 L 37.5 75';
    }
      
    return (`
      url('data:image/svg+xml;utf8,
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <path
            d="${arrow}"
            stroke="${color.replace('#', '%23')}"
            stroke-width="11"
            fill="transparent"
          />
        </svg>
      ')
    `);
  }} no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: ${({ showArrowButtons }) => showArrowButtons ? 'initial' : 'none'};
  height: 4em;
  width: 4em;
  position: absolute;
  left: ${({ direction }) => direction === 'left' ? '5px' : 'auto'};
  right: ${({ direction }) => direction === 'right' ? '5px' : 'auto'};
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`;

const Indicator = styled.button`
  ${ buttonStyleOverride }
  border: ${({ color }) => `.15em solid ${color}`};
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 1em;
  margin: .1em;
  padding: 0;
  width: 1em;
`;

const Slider = ({
  absolutePositionSpacer,
  autoPlay = true,
  canSwipe = false,
  className,
  controlColor = 'white',
  showArrowButtons = true,
  showIndicators = true,
  slides,
  slideInterval = 4000,
  slideSpacing = 0.4,
  style,
  transitionTime = 1500,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState('');
  const [paused, setPaused] = useState(false);
  const [hovering, setHovering] = useState(false);

  const slideRight = () => {
    if (!paused) {
      setPaused(true);
      setAnimationClass('slideRight');
      setSelectedIndex((selectedIndex + 1) % slides.length);
    }
  };

  const slideLeft = () => {
    if (!paused) {
      setPaused(true);
      setAnimationClass('slideLeft');
      const nextIndex = selectedIndex - 1;
      if (nextIndex < 0) {
        setSelectedIndex(slides.length - 1);
      } else {
        setSelectedIndex(nextIndex);
      }
    }
  };

  const setSlide = (newIndex) => {
    setAnimationClass('fadeIn');
    setSelectedIndex(newIndex);
  };

  const slideTime = slideInterval + transitionTime;
  useEffect(() => {
    if (autoPlay) {
      const timer = setInterval(() => {
        if (!hovering) slideRight();
      }, slideTime);
      return () => clearInterval(timer);
    }
  }, [slideRight, slideTime, selectedIndex]);

  useEffect(() => {
    if (paused) {
      const timer = setTimeout(() => {
        setPaused(false);
      }, transitionTime);
      return () => clearTimeout(timer);
    }
  }, [paused]);

  let distanceSwiped = 0;
  let startingClientX;
  let dragging = false;
  const startSwipe = (e) => {
    if (canSwipe && !paused) {
      startingClientX = e.touches ? e.touches[0].pageX : e.clientX;
      dragging = true;
    }
  }

  const endSwipe = (e)  => {
    if (canSwipe && !paused) {
      dragging = false;
      if (Math.abs(distanceSwiped) / e.currentTarget.offsetWidth > 0.1) {
        if (distanceSwiped < 0) {
          slideRight();
        } else {
          slideLeft();
        }
      } else {
        if (Math.abs(distanceSwiped) > 0) {
          e.currentTarget.style.removeProperty('transform');
        }
      }
    }
  }

  const swipe = (e) => {
    if (canSwipe && !paused) {
      const clientX = e.touches ? e.touches[0].pageX : e.clientX;
      if (dragging) {
        distanceSwiped = clientX - startingClientX;
        e.currentTarget.style.transform = `translateX(${distanceSwiped}px)`;
      }
    }
  }

  return (
    <StyledDiv
      className={`${className} ${animationClass} ${absolutePositionSpacer ? 'absolutePositioning' : ''}`}
      slideSpacing={slideSpacing}
      transitionTime={transitionTime}
      style={style}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <div
        className="innerWrapper"
        style={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <ArrowButton
          className="arrowButton"
          direction="left"
          color={controlColor}
          onClick={slideLeft}
          showArrowButtons={showArrowButtons}
        />
        <TransitionGroup style={{ display: 'flex'}}>
          <CSSTransition
            classNames="slide"                                    
            timeout={{ enter: transitionTime, exit: transitionTime }}                                 
            key={selectedIndex}
          >
            <Slide
              absolutePositionSpacer={absolutePositionSpacer}
              canSwipe={canSwipe}
              onMouseDown={startSwipe}
              onMouseUp={endSwipe}
              onMouseMove={swipe}
              onTouchStart={startSwipe}
              onTouchEnd={endSwipe}
              onTouchCancel={endSwipe}
              onTouchMove={swipe}
            >
              {slides[selectedIndex]}
            </Slide>
          </CSSTransition>                              
        </TransitionGroup>
        {absolutePositionSpacer && absolutePositionSpacer({ style: { opacity: 0 } })}
        <ArrowButton
          className="arrowButton"
          direction="right"
          color={controlColor}
          onClick={slideRight}
          showArrowButtons={showArrowButtons}
        />
      </div>
      {
        showIndicators && (
          <div style={{ marginTop: '3em', textAlign: 'center' }}>
            {
              slides.map((slide, index) => (
                <Indicator
                  key={index}
                  color={controlColor}
                  onClick={() => setSlide(index)}
                  style={{ background: index === selectedIndex ? controlColor : 'none' }}
                />)
              )
            }
          </div>
        )
      }
    </StyledDiv>
  );
};

Slider.propTypes = {
  absolutePositionSpacer: PropTypes.func, // function returning a React node or html element
  autoPlay: PropTypes.bool,
  canSwipe: PropTypes.bool,
  controlColor: PropTypes.string,
  showArrowButtons : PropTypes.bool,
  showIndicators: PropTypes.bool,
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
  slideInterval: PropTypes.number,
  slideSpacing: PropTypes.number,
  style: PropTypes.object,
  transitionTime: PropTypes.number,
};

export default Slider;
