import React from 'react';
import styled from 'styled-components';

import ResponsiveSection from '../components/ResponsiveSection';
import theme from '../theme';

const Hero = ({ children, className }) => (
  <ResponsiveSection className={className}>
    {children}
  </ResponsiveSection>
);

const StyledHero = styled(Hero)`
  .inner-div {
    padding-top: calc(${theme.fonts.rhythm} * 5);
    padding-bottom: 0;

    & > h1 > span span {
      display: block;
    }
  }

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    .inner-div {
      padding-top: calc(${theme.fonts.rhythm} * 8);
      padding-bottom: calc(${theme.fonts.rhythm} * 2);
    }
  }

  @media screen and (min-width: ${theme.breakpoints.md}) {
    .inner-div {
      & > h1 > span {
        display: block;
      }
      & > h1 > span span {
        display: inline-block;
      }
    }
  }

  @media screen and (max-width: 510px) {
    .inner-div > h1 {
      font-size: 10.25vw;
    }
  }
`;

export default StyledHero;
