import React from 'react';
import styled from 'styled-components';
import StephenSontagImage from '../images/testimonials/stephen-sontag.jpg';
import LuigiGianiImage from '../images/testimonials/luigi-giani.jpeg';
import ResponsiveSection from './ResponsiveSection';
import Slider from './Slider';
import theme from '../theme';

const testimonials = [{
  imageURL: StephenSontagImage,
  name: `Stephen Sontag`,
  organization: `Map Blender`,
  role: `Owner`,
  statement: `I have been involved in big projects for major corporations and the team at Mindfire is the best that I have worked with.`,
}, {
  imageURL: LuigiGianiImage,
  name: `Luigi Giani`,
  organization: `Water for Cambodia`,
  role: `Director of Operations`,
  statement: `I had a great experience with Digital Rabbit. Anything from service to price, to quality of final product, fully satisfied our needs. Highly recommended!`,
}, {
  imageURL: StephenSontagImage,
  name: `Test Testerson 222`,
  organization: `Test Org`,
  role: `Test Role`,
  statement: `I had a great experience with Digital Rabbit. Anything from service to price, to quality of final product, fully satisfied our needs. Highly recommended!`,
}];

const longestTestimonialIndex = testimonials
  .reduce((a, b, index) => {
    const valuesInfo = {
      length: Object.entries(b)
        .map(([key, value]) => {
          if (key === 'imageURL') return;
          return value;
        })
        .join()
        .length,
      index,
    };

    return valuesInfo.length > a.length ? valuesInfo : a;
  }, { length: 0, index: 0 }).index;

const Testimonial = ({
  className,
  imageURL,
  organization,
  name,
  role,
  statement,
  style,
}) => (
    <div className={className} style={{ maxWidth: '800px', width: '100%', padding: '.5em', ...style }}>
      <h2>"{statement}"</h2>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={imageURL}
          alt="Stephen Sontag"
          style={{
            borderRadius: '50%',
            marginRight: '.75em',
            height: '5em',
            width: '5em',
          }}
        />
        <div style={{ fontSize: '1.25em' }}>
          <span style={{ fontWeight: 'bold' }}>{name}</span>
          <br />
          <span style={{ fontStyle: 'italic' }}>{role}</span>
          <br />
          <span style={{ fontWeight: 'bold' }}>{organization}</span>
        </div>
      </div>
    </div>
);

const StyledTestimonial = styled(Testimonial)`
  font-size: .75em;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    font-size: 1em;
  }
`;

const StyledSlider = styled(Slider)`
  .arrowButton {
    display: none;
  }
  @media screen and (min-width: 1025px) {
    .arrowButton {
      display: initial;
    }
  }
`;

const TestimonialsSection = () => (
  <ResponsiveSection>
    <StyledSlider
      slides={testimonials.map((testimonial, index) => (
        <StyledTestimonial index={index} key={testimonial.name} {...testimonial} />)
      )}
      absolutePositionSpacer={(props) => <StyledTestimonial {...testimonials[longestTestimonialIndex]} {...props} />}
    />
  </ResponsiveSection>
);

export default TestimonialsSection;
