import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../../theme';

const IconDiv = styled.div`
  font-size: 2.25rem;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    font-size: 3.5rem;
  }
`;

const FeatureCard = ({
  title,
  image,
  content,
}) => (
  <div> 
    <IconDiv style={{ margin: `1rem 0` }}>{image}</IconDiv>
    <h3>{title}</h3>
    { content && <div style={{ textAlign: 'left', }}>{content}</div> }
  </div>
);

FeatureCard.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  image: PropTypes.node,
  content: PropTypes.string,
};

const StyledFeatureCard = styled(FeatureCard)`
  display: flex;
  flex-direction: column;
`;

export default StyledFeatureCard;
