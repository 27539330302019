import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CSSTransition,
  SwitchTransition,
} from 'react-transition-group';

import './index.scss';

const WordFlipper = ({ color, wordsArray }) => {
  const [wordIndex, setWordIndex] = useState(0);

  const firstUpdate = useRef(true);

  useEffect(() => {
    const timeout = firstUpdate.current ? 750 : 1500;
    if (firstUpdate.current) firstUpdate.current = false;
    let timeoutFunc;
  
    if (wordIndex < wordsArray.length - 1) {
      timeoutFunc = setTimeout(() => {
        setWordIndex(prev =>  prev + 1);
      }, timeout);
    } else {
      timeoutFunc = setTimeout(() => {
        setWordIndex(0);
      }, timeout * 5);
    }

    return () => clearTimeout(timeoutFunc);
  }, [wordsArray, wordIndex]);

  return (
    <SwitchTransition mode={'out-in'}>
      <CSSTransition
        key={wordIndex}
        timeout={500}
        classNames="flip"
      >
        <span style={{ color }}>{wordsArray[wordIndex]}</span>
      </CSSTransition>
    </SwitchTransition>
  );
};

WordFlipper.defaultProps = {
  color: '',
};

WordFlipper.propTypes = {
  color: PropTypes.string,
  wordsArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default WordFlipper;
