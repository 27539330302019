import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTv, faChartBar } from '@fortawesome/free-solid-svg-icons';
import ResponsiveSection from '../ResponsiveSection';

import FeatureCard from './FeatureCard';
import theme from '../../theme';

const { wrapperPadding } = theme.layout;

const StyledFeatureGrid = styled.div`
  display: grid;
  grid-gap: calc(${wrapperPadding} * 2);

  @media screen and (min-width: ${theme.breakpoints.md}) {
    grid-template-columns: ${() => elements.map(() => '1fr').join(' ')};
  }
`;

const elements = [
  {
    title: <span>Apps&nbsp;&amp; Websites</span>,
    image: <FontAwesomeIcon icon={faTv} />,
    content: `Have an idea for a new app or website? Need a software developer but don't have the resources in-house? From idea to finished product, Digital Rabbit has you covered.`,
  },
  {
    title: 'Interactive Data',
    image: <FontAwesomeIcon icon={faChartBar} />,
    content: `Turn data into insights that drive decisions. Digital Rabbit builds products that let you analyze data from any source with speed and clarity.`,
  },
];

const FeatureGridSection = ({ className }) => {
  const rows = elements.map(({ title, image, content }) => (
    <FeatureCard key={title} title={title} image={image} content={content} />
  ));

  return (
    <ResponsiveSection>
      <StyledFeatureGrid>{rows}</StyledFeatureGrid>
    </ResponsiveSection>
  );
};

FeatureGridSection.defaultProps = {
  rowLength: 0,
};

FeatureGridSection.propTypes = {
  rowLength: PropTypes.number,
};

export default FeatureGridSection;
