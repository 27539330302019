import React from 'react';
import styled from 'styled-components';

import theme from '../theme';
import ResponsiveSection from '../components/ResponsiveSection';
import Peloton from '../images/social-proof-logos/peloton.svg';
import Habitat from '../images/social-proof-logos/habitat-for-humanity.svg';
import LeCreuset from '../images/social-proof-logos/lecreuset.svg';
import Principal from '../images/social-proof-logos/principal.svg';
import Rotary from '../images/social-proof-logos/rotary.svg';
import FiveGuys from '../images/social-proof-logos/five-guys.svg';
import LLBean from '../images/social-proof-logos/ll-bean.svg';
import ShakeShack from '../images/social-proof-logos/shake-shack.svg';
import ContainerStore from '../images/social-proof-logos/container-store.svg';
import Duluth from '../images/social-proof-logos/duluth.svg';

const angle = 3;
const width = '100vw';
const maxWidth = theme.layout.maxWidth;
const magicNumber = Math.tan(angle * Math.PI / 180) / 2;
const skewPadding = `calc(${width} * ${magicNumber})`;
const skewPaddingLg = `calc(${maxWidth} * ${magicNumber})`;

const StyledResponsiveSection = styled(ResponsiveSection)`
  position: relative;
  margin-top: ${skewPadding};
  margin-bottom: ${skewPadding};

  .inner-div {
    position: relative;
  }
  
  &:before {
    background: ${theme.colors.accent};
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: skewy(${angle}deg);
    transform-origin: 50% 0;
    outline: 1px solid transparent;
    backface-visibility: hidden;
  }

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    margin-top: ${skewPaddingLg};
    margin-bottom: ${skewPaddingLg};
  }
`;

const LogoDiv = styled.div`
  svg {
    margin: 0.75em;
    width: 6em;
  }

  @media screen and (min-width: ${theme.breakpoints.md}) {
    svg {
      margin: 1em;
      width: 8em;
    }
  }
`;

const SocialProofSection = () => {
  const companyLogos = {
    Peloton,
    FiveGuys,
    LLBean,
    ShakeShack,
    Principal,
    Duluth,
    LeCreuset,
    Habitat,
    ContainerStore,
    Rotary,
  };

  return (
    <StyledResponsiveSection>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          flexDirection: 'column',
        }}
      >
        <h3 style={{ textAlign: 'center' }}>Great companies rely on Digital Rabbit <span style={{ lineHeight: 1 }} role="img" aria-label="sparkle icon">✨</span></h3>
        <br />
        <br />
        <LogoDiv
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          { Object.entries(companyLogos).map(([key, Logo]) => <Logo key={key} />) }
        </LogoDiv>
      </div>
    </StyledResponsiveSection>
  );
};

export default SocialProofSection;
