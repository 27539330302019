import React from 'react';
import { Button } from 'digital-rabbit-cl';
import { navigate } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Hero from '../components/Hero';
import FeatureGridSection from '../components/FeatureGridSection';
import WordFlipper from '../components/WordFlipper';
import SocialProofSection from '../components/SocialProofSection';
import TestimonialsSection from '../components/TestimonialsSection';
import theme from '../theme';

const projectTypes = [
  'web app',
  'dashboard',
  'visualization',
  'analytics tool',
  'project',
];

const IndexPage = () => (
  <Layout>
    <SEO title="Custom Softare Development" />
    <Hero>
      <h1>
        <span>
          Build your&nbsp;next{' '}
          <WordFlipper color={theme.colors.accent} wordsArray={projectTypes} />{' '}
        </span>
        with Digital&nbsp;Rabbit
      </h1>
      <Button color={theme.colors.accent} onClick={() => navigate('/contact')}>
        Let's Talk →
      </Button>
    </Hero>
    <FeatureGridSection />
    {/* <SocialProofSection />
    <TestimonialsSection /> */}
  </Layout>
);

export default IndexPage;
